import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
	UseMutationOptions,
	DefaultOptions,
	UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

const queryConfig: DefaultOptions = {
	queries: {
		refetchOnWindowFocus: false,
		retry: false,
	},
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
	ReturnType<FnType>
>;

export type QueryConfig<T extends (...args: any) => any> = Omit<
	UseQueryOptions<ExtractFnReturnType<T>>,
	'queryKey' | 'queryFn'
>;

export type MutationConfig<T extends (...args: any) => any> = UseMutationOptions<
	ExtractFnReturnType<T>,
	AxiosError,
	Parameters<T>[0]
>;

export { useQuery, useMutation, useQueryClient };
