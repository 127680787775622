import { default as legacyRouter } from '@kemtai/components/dist/router';
import * as Sentry from "@sentry/react";
import { ENVIRONMENT, SENTRY_DSN, VERSION } from 'config';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';


const initApp = () => {
	legacyRouter.disable();
	
	if (ENVIRONMENT !== "development") {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: VERSION,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/[a-z]+-api.kemtai.com/, /^https:\/\/api.kemtai.com/],
      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          networkDetailAllowUrls: [
            window.location.origin,
            /^https:\/\/[a-z]+-api.kemtai.com/, 
            /^https:\/\/api.kemtai.com/,
          ],
          blockAllMedia: false,
        }),
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
    });
  }
};

export default initApp;
