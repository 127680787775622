class Storage {
	PREFIX = 'kemtai_dashboard';

	getItem(key: string) {
		return JSON.parse(window.localStorage.getItem(`${this.PREFIX}_${key}`) as string);
	}

	setItem(key: string, value: any) {
		window.localStorage.setItem(`${this.PREFIX}_${key}`, JSON.stringify(value));
	}

	clearItem(key: string) {
		window.localStorage.removeItem(`${this.PREFIX}_${key}`);
	}

	getAccessToken() {
		return this.getItem('accessToken');
	}

	setAccessToken(token: string) {
		this.setItem('accessToken', token);
	}

	clearAccessToken() {
		this.clearItem('accessToken');
	}

	getRefreshToken() {
		return this.getItem('refreshToken');
	}

	setRefreshToken(token: string) {
		this.setItem('refreshToken', token);
	}

	clearRefreshToken() {
		this.clearItem('refreshToken');
	}
}

export const storage = new Storage();
